<template>
  <div class="content">
    <div class="bgImg">
      <img class="cardImg"
           :src="ossurl + form.pichttp" />
      <simple-cropper :initParam="uploadParam"
                      :successCallback="uploadHandle"
                      ref="cropper"
                      class="box">
        <div class="bgChange"
             @click="upload('cropper')">更换背景</div>
      </simple-cropper>
      <div class="letter">
        <div>
          <div class="anim_bg1"
               v-show="isRecord"></div>
          <div class="anim_bg2"
               v-show="isRecord"></div>
          <img class="yuyinImg"
               src="../../../../assets/abt/img/yuyin.png"
               @click="record" />
          <img src="../../../../assets/abt/img/pause1.png"
               class="bofang"
               v-show="playluyin == 1"
               @click="isplay" />
          <img src="../../../../assets/abt/img/loadmore.png"
               class="bofang"
               v-show="playluyin == 2"
               @click="isplay" />
          <audio :src="form.conthttp"
                 style="display:none"
                 id="audio"></audio>
        </div>
        <!-- <div class="bgChange zhufu" >更多精彩祝福</div> -->
        <div class="text">
          <input v-model="form.stitle"
                 placeholder="请输入姓名" /><br />
          <textarea v-model="form.sdesc"
                    rows="4"
                    maxlength="120"
                    placeholder="请输入祝福语"></textarea>
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="applyBtn"
           :style="`backgroundColor:${colorprimary}`"
           @click="createCard">生成贺卡</div>
    </div>
  </div>
</template>
<script>
import { selectOneBySno } from '@/api/abt/customerOperation/common/index'
import { useHK } from '@/api/abt/customerOperation/greetingcard'
import {
  getConfig,
  voiceStart,
  voiceEnd,
  voiceplay,
  voiceplayend
} from '@/lib/wxapi'
import config from '@/config'
import { getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
import simpleCropper from '../visitingcard/component/simpleCropper'
export default {
  components: {
    simpleCropper
  },
  data () {
    return {
      user: '',
      ossurl: '',
      height: '',
      width: '',
      uploadParam: {
        file: 'HK',
        type: 'HK',
        fileType: 'recruit', // 其他上传参数
        uploadURL: '/empno/getUpload', // 上传地址
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
        ratio: 1 / 2
        // userid: localStorage.getItem(window.g.beforestr + '_userid'),
        // comid: localStorage.comid
      }, // 上传头像初始化数据
      form: {
        pichttp: '',
        sno: '',
        stitle: '',
        sdesc: '',
        stagcode: ''
      },
      sharecode: '',
      sno: '',
      isRecord: false,
      playluyin: 0,
      voice_flag: '',
      play: false,
      colorprimary: ''
    }
  },
  created () {
    this.height = document.body.clientHeight
    this.width = document.body.clientWidth
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.sno = this.$route.query.sno
    this.getData()
  },
  mounted () {
    document.querySelector('.bgImg').style.height = `${this.height - 35}px`
    document.querySelector('.bgImg').style.width = `${this.width}px`
    // eslint-disable-next-line no-undef
    $(document).on('focusout', function () {
      setTimeout(() => {
        // 软键盘收起的事件处理
        window.scroll(0, 0)
      }, 300)
    })
    let path = ''
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // ios
      if (this.$route.query.frompage == 'source') {
        path = config.redirect_uri + '/source'
      } else {
        path = config.redirect_uri
      }
    } else {
      // android
      path = window.location.href
    }
    getConfig(path)
  },
  methods: {
    getData () {
      selectOneBySno({ btagcode: '4', sno: this.sno, flag: '0' }).then(res => {
        this.form.pichttp = res.data.data.pichttp
        this.form.sdesc = res.data.data.sdesc
        this.form.stagcode = res.data.data.stagcode
      })
    },
    createCard () {
      if (this.form.stitle == '') {
        Toast.info('请填写姓名')
        return
      }
      if (this.form.sdesc == '') {
        Toast.info('请填写祝福')
        return
      }
      let data = {
        pichttp: this.form.pichttp,
        stitle: this.form.stitle,
        sdesc: this.form.sdesc,
        stagcode: this.form.stagcode,
        conthttp: this.voice_flag,
        rytype: this.user.rytype
      }
      useHK(data).then(res => {
        this.sharecode = res.data.data
        Toast.succeed('创建成功')
        this.$router.push({
          name: 'cardshare',
          query: {
            sno: this.sharecode,
            empno: this.user.empno,
            suserid: this.user.userid
          }
        })
      })
    },
    // 上传图片
    upload (ele) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log(this.$refs[ele].upload())
      }
      this.$refs[ele].upload()
    },
    // 上传图片成功回调
    uploadHandle (data) {
      this.form.pichttp = data.path
      Toast.succeed('上传成功')
    },
    record () {
      this.isRecord = !this.isRecord
      if (this.isRecord) {
        voiceStart()
      } else {
        voiceEnd().then(res => {
          this.form.conthttp = res.show
          this.voice_flag = res.save
          this.playluyin = 1
        })
      }
    },
    isplay () {
      this.play = !this.play
      if (this.play) {
        this.playluyin = 2
        voiceplay().then(res => {
          this.playluyin = 1
        })
      } else {
        voiceplayend()
        this.playluyin = 1
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.bgImg {
  position: relative;
  overflow: hidden;

  .cardImg {
    width: 100%;
    height: 100%;
  }

  .letter {
    background-image: url('../../../../assets/abt/img/letter.png');
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 86.5%;
    background-size: cover;
    background-repeat: no-repeat;

    .anim_bg1 {
      position: absolute;
      width: 1.8rem;
      height: 1.8rem;
      left: 42%;
      bottom: 22%;
      z-index: 19;
      border-radius: 50%;
      background: rgba(119, 179, 254, 0.8);
      animation: sc_anim1 1.4s ease-out infinite;
      -webkit-animation: sc_anim1 1.4s ease-out infinite;
    }

    .anim_bg2 {
      position: absolute;
      width: 1.8rem;
      height: 1.8rem;
      left: 42%;
      bottom: 22%;
      z-index: 9;
      border-radius: 50%;
      background: rgba(119, 179, 254, 0.8);
      animation: sc_anim2 1.4s ease-out infinite;
      -webkit-animation: sc_anim2 1.4s ease-out infinite;
    }

    .yuyinImg {
      position: absolute;
      bottom: 25%;
      left: 44.5%;
      width: 130px;
      height: auto;
      z-index: 100;
    }

    .bofang {
      position: absolute;
      bottom: 10%;
      left: 15%;
      width: 130px;
      height: auto;
      z-index: 100;
    }

    .text {
      position: absolute;
      top: 20%;
      left: 15%;

      input {
        font-size: 35px;
        position: absolute;
        top: 7px;
        left: 50px;
        background-color: rgba(0, 0, 0, 0);
        background: rgba(0, 0, 0, 0);
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
      }

      textarea {
        font-size: 36px;
        background: transparent;
        position: absolute;
        top: 80px;
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
        width: 750px;
        text-indent: 2em;
        border: none;
        line-height: 160%;
        outline: 0;
      }
    }
  }
}

.bgChange {
  position: absolute;
  top: 100px;
  right: 50px;
  border: 1px solid #fff;
  // color-primary
  color: #fff;
  padding: 14px 60px;
  border-radius: 25px;
}

.zhufu {
  position: absolute;
  top: 75%;
  right: 100px;
  padding: 14px 30px;
  border: 1px solid #000;
  color: #000;
}

.btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;

  .applyBtn {
    text-align: center;
    padding: 20px 30px;
    width: 100%;
    //background-color: color-primary;
    color: #fff;
    border-radius: 10px;
    font-size: 40px;
  }
}

@keyframes sc_anim1 {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1.6);
    opacity: 0.1;
  }
}

@keyframes sc_anim2 {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  50% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1.6);
    opacity: 0.1;
  }
}
</style>
